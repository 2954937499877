import React, { useState, useEffect } from 'react'
import Select from "react-select"
import Modal from '../../Modal'
import style from './CovidFluImmunizationForm.module.scss'
import { DatePicker } from "rsuite"
import axios from 'axios'
import ReactOnRails from 'react-on-rails'

const EmployeeImmunizationForm = ({type, employeeId, onFormSubmitSuccess}) => {
  const [statusSelection, setStatusSelection] = useState(false)
  const [receivedOnsite, setReceivedOnsite] = useState(false)
  const [immunizationDate, setImmunizationDate] = useState('')
  const [declineDate, setDeclineDate] = useState('')
  const [leadership, setLeadership] = useState([])
  const [vaccines, setVaccines] = useState([])
  const [receivedOnsiteVisible, setReceivedOnsiteVisible] = useState(false)
  const [declineStatusVisible, setDeclineStatusVisible] = useState(false)
  const [receivedStatusVisible, setReceivedStatusVisible] = useState(false)
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '6px',
      border: '1px solid #ddd',
      minWidth: '210px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: '#999'
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
      zIndex: '99',
      overflow: 'visible'
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '14px',
    })
  }

  useEffect(() => {
    const fetchLeadership = async () => {
      const response = await axios.get('/employees', {
        params: {
          filter: 'leadership'
        }
      });
      const leadership = response.data.employees
      setLeadership(leadership)
    }
    const fetchVaccines = async () => {
      const response = await axios.get(`${type.toLowerCase()}_vaccines`);
      const vaccines = response.data.vaccines
      setVaccines(vaccines)
    }
    fetchVaccines()
    fetchLeadership()
  }, [])

  useEffect(() => {
    if (statusSelection === false) {
      setReceivedOnsiteVisible(false)
      setDeclineStatusVisible(false)
      setReceivedStatusVisible(false)
    } else if (statusSelection === 'received') {
      setReceivedStatusVisible(true)
      setDeclineStatusVisible(false)
    } else if (statusSelection === 'declined') {
      setDeclineStatusVisible(true)
      setReceivedStatusVisible(false)
    }
  }, [statusSelection])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formObject = Object.fromEntries(formData.entries())

    const immunization = {
        date_administered: immunizationDate,
        date_declined: declineDate,
        received_onsite: receivedOnsite,
        status: statusSelection,
        employee_id: employeeId
    }
    const employeeImmunization = {...formObject, ...immunization}

    try {
      const response = await axios.post(`/${type.toLowerCase()}_immunizations`, employeeImmunization, {
        headers: ReactOnRails.authenticityHeaders()
      })

      if(response.status === 200) {
        alert('Immunization has been added')
        onFormSubmitSuccess()
      }
    } catch(error) {
      alert(error.response.data.message)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={style.title}>{type} Immunization:</div>
        <div className={style.container}>
          <div className={style.formTable}>
            <label htmlFor="status">Status:</label>
            <Select
              styles={customStyles}
              name="status"
              id="status"
              options={[
                { value: 'received', label: 'Received' },
                { value: 'declined', label: 'Declined'}
              ]}
              placeholder="Select Status"
              required={true}
              onChange={(e) => { e === null ? setStatusSelection(false) : setStatusSelection(e.value) }}
              isClearable={true}
              isSearchable={false}
            />
            { receivedStatusVisible && (
              <>
                <label htmlFor="date_administered">Date Received:</label>
                < DatePicker
                  className={style.datePicker}
                  name="date_administered"
                  id="date_administered"
                  format='MM/dd/yyy'
                  oneTap
                  onChange={(e) => setImmunizationDate(e)}
                  placeholder="MM/DD/YYYY"
                  cleanable={true}
                  required={true}
                  placement='topEnd'
                />
                <label htmlFor="received_onsite">Received On Site?</label>
                <Select
                  styles={ customStyles }
                  className={style.dropdownBox}
                  name="received_onsite"
                  id="received_onsite"
                  options={
                    [{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                  placeholder="Yes/No"
                  onChange={(e) => {setReceivedOnsiteVisible(e.value)}}
                  required={true}
                  isSearchable={false}
                  menuPlacement='auto'
                />
              </>
            )}
            { receivedOnsiteVisible && (
              <>                              
                <label htmlFor="administered_by_id">Who administered?</label>
                <Select
                  styles={ customStyles }
                  className={style.dropdownBox}
                  name="administered_by_id"
                  id="administered_by_id"
                  options={leadership.map((employee) => { return { value: employee.id, label: `${employee.full_name}` } })}
                  placeholder="Select Employee"
                  required={true}
                  isSearchable={false}
                  isClearable={true}
                  menuPlacement='auto'
                />
                <label htmlFor={`${type.toLowerCase()}_vaccine_id`}>Which vaccine was administered?</label>
                <Select
                  styles={ customStyles }
                  className={style.dropdownBox}
                  name={`${type.toLowerCase()}_vaccine_id`}
                  id={`${type.toLowerCase()}_vaccine_id`}
                  options={vaccines.map((vaccine) => { return { value: vaccine.id, label: `${vaccine.brand}` } })}
                  placeholder="Select Vaccine"
                  required={true}
                  isSearchable={false}
                  menuPlacement='auto'
                  maxMenuHeight={200}
                />
              </>
            )}
            { declineStatusVisible && (
              <>
                <label htmlFor="date_declined">Date Declined:</label>
                < DatePicker
                  className={style.datePicker}
                  name="date_declined"
                  id="date_declined"
                  format='MM/dd/yyy'
                  oneTap
                  onChange={(e) => setDeclineDate(e)}
                  placeholder="MM/DD/YYYY"
                  cleanable={true}
                  required={true}
                  placement='topEnd'
                />
                <label htmlFor="decline_reason">Reason for Decline:</label>
                <Select
                  styles={ customStyles }
                  name="decline_reason"
                  id="decline_reason"
                  options={[
                    { value: 'Medical Contraindication', label: 'Medical Contraindication' },
                    { value: 'Offered but Declined', label: 'Offered but Declined' },
                    { value: 'Unknown/Other', label: 'Unknown/Other'}
                  ]}
                  placeholder="Select a Reason"
                  isSearchable={false}
                  menuPlacement='auto'
                  maxMenuHeight={200}
                  required={true}
                  isClearable={true}
                />
              </>
            )}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <button className={style.addImmunizationButton} type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

export default EmployeeImmunizationForm